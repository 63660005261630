import React from 'react';

import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { Header, Form, Segment } from 'semantic-ui-react';

import { Formik } from 'formik';

import {
  Field,
  FastField,
  FormRow,
  CompanyNewSelect,
  CheckboxField,
  RichTextAreaField,
  InputField,
  FileField,
  UpdateButtonInRow,
  TagSelect,
  DateTimeField,
  UnsavedFormPrompt,
  ErrorsAndButtonsInRow,
  NonFormValuesDisplay,
  WebArticleDeleteModal,
  UniSelect,
  WebArticlePricesUpdateModal,
  Tooltip,
  TextAreaField
} from 'components';

import { PERMISSIONS, VisibleIf, hasPermission } from 'permissions';

import { pageUrl } from 'config/routes';

import { AdminSelect, CountrySelect, RegionSelect } from 'pages/Article/fields';

import { handleFormErrors, prepareFormData } from 'utils/forms';
import { notifySuccess } from 'utils/notifications';
import { prepareNewCompanies } from 'pages/WebArticle/utils';

import { articleUpdate, articleDelete } from './sdk';
import { articleUpdateSchema } from './schema';
import { getNonFormItems, getInitialValues } from './utils';

import styles from './styles.module.css';
import { WebArticleList } from 'pages/WebArticle';

import { POWER_MATERIAL_SLUG } from 'domainConstants';

class WebArticleUpdateForm extends React.Component {
  state = {
    withExitExtraActions: false,
    deleteRequested: false,
    updatePricesModalOpen: false,
    updatedArticle: null
  };

  onClosePricesModal = async () => {
    this.setState({ updatePricesModalOpen: false });

    if (this.state.withExitExtraActions) {
      this.props.history.push(pageUrl(WebArticleList));
    }
  };

  setDeleteRequested = deleteRequested => {
    this.setState({ deleteRequested: deleteRequested });
  };

  handleSubmit = async (values, actions) => {
    const { fetchArticle } = this.props;
    const { productSlug, articleId } = this.props.match.params;

    const { setSubmitting, setFieldError } = actions;

    const formData = prepareFormData(values);

    let companiesData = formData.related_companies;

    // prepare companies data
    formData.new_companies = prepareNewCompanies(companiesData, productSlug);
    formData.related_companies = _.filter(companiesData, _.isInteger);

    formData.insert_in_newsletter_type_slug = 'daily';
    formData.insert_in_newsletter_sections = [
      'region-articles',
      'product-type-group-articles'
    ];

    setSubmitting(true);

    const { errors, success, data } = await articleUpdate(
      productSlug,
      articleId,
      formData
    );

    this.setState({ updatedArticle: data });

    if (success) {
      notifySuccess('Article updated.');

      this.setState({ updatePricesModalOpen: true });

      if (this.state.withExitExtraActions) {
        this.props.history.push(pageUrl(WebArticleList, { productSlug }));
      } else {
        fetchArticle();
      }

      setSubmitting(false);

      return;
    } else {
      handleFormErrors(errors, setFieldError);
    }

    setSubmitting(false);
  };

  onUpdateAndExitClick = cb => {
    this.setState({ withExitExtraActions: true }, cb);
  };

  handleFreeChange = (value, setFieldValue) => {
    setFieldValue('free', value);

    // If `Free` is unmarked - unmark `Featured` automatically
    if (!value) {
      setFieldValue('featured', false);
    }
  };

  handleFeaturedChange = (value, setFieldValue) => {
    setFieldValue('featured', value);

    // If `Featured` is marked - mark `Free` automatically
    if (value) {
      setFieldValue('free', true);
    }
  };

  render() {
    const { article, user, productSlug } = this.props;

    const defaultCompanySelectValue = _.map(
      article.related_companies,
      company => ({
        key: company.id,
        value: company.id,
        label: company.title
      })
    );

    const initialValues = getInitialValues(article);

    const nonFormValues = {
      createdBy: article.created_by,
      createdAt: article.created_at,
      updatedBy: article.updated_by,
      updatedAt: article.updated_at,
      absoluteUrl: article.absolute_url,
      readyForPublicationDate: article.ready_for_publication_date
    };

    const hasNoPermissionToUpdateRfpArticles =
      !hasPermission(user, PERMISSIONS.CHANGE_RFP_ARTICLES) &&
      (article.status === 'Live' || article.status === 'Ready for Publication');
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={articleUpdateSchema}
        onSubmit={this.handleSubmit}
        enableReinitialize={true}>
        {({
          handleSubmit,
          isSubmitting,
          dirty,
          errors,
          touched,
          setFieldValue,
          values
        }) => (
          <Form>
            <FormRow>
              <UnsavedFormPrompt
                when={
                  dirty &&
                  !isSubmitting &&
                  !this.state.deleteRequested &&
                  !this.state.updatePricesModalOpen
                }
              />
              {this.state.updatedArticle && (
                <WebArticlePricesUpdateModal
                  productSlug={productSlug}
                  article={this.state.updatedArticle}
                  caller={'article'}
                  open={this.state.updatePricesModalOpen}
                  onClose={this.onClosePricesModal}
                />
              )}
              <FastField
                required
                width={10}
                name="fullHeadline"
                component={InputField}
                label="Full headline"
              />
              <Form.Field width={6}>
                <UpdateButtonInRow
                  showWithExitBtn
                  subject="Article"
                  onClick={handleSubmit}
                  disabled={isSubmitting || hasNoPermissionToUpdateRfpArticles}
                  onUpdateAndExitClick={this.onUpdateAndExitClick}
                />
              </Form.Field>
            </FormRow>
            <FormRow>
              <FastField
                width={14}
                required
                name="marketingHeadline"
                component={InputField}
                label="Marketing headline"
              />
            </FormRow>
            <FormRow>
              <FastField
                width={4}
                name="publishDate"
                component={DateTimeField}
                label="Publish Date"
              />
              <FastField
                required
                width={4}
                name="slug"
                component={InputField}
                label="Slug"
              />
              <UniSelect
                width={4}
                name="type"
                label="Type"
                productSlug={productSlug}
                source="articleLabels"
                required
              />
              <UniSelect
                width={4}
                name="status"
                label="Status"
                productSlug={productSlug}
                source="articleStatus"
                required
              />
            </FormRow>
            <FormRow>
              <AdminSelect required width={8} name="authors" label="Authors" />
              <Tooltip
                on="hover"
                content="Display 'By Kallanish Team' in the authors section and hide all authors' names"
                trigger={
                  <Field
                    width={2}
                    name="byKallanishTeam"
                    label="By Kallanish team"
                    component={CheckboxField}
                  />
                }
              />
              <Tooltip
                on="hover"
                content="Display 'By Kallanish Asia' in the authors section and hide Asian authors' names"
                disabled={values.byKallanishTeam}
                trigger={
                  <Field
                    width={2}
                    disabled={values.byKallanishTeam}
                    name="byKallanishAsia"
                    label="By Kallanish Asia"
                    component={CheckboxField}
                  />
                }
              />
            </FormRow>
            <UniSelect
              width={8}
              name="articleTopics"
              label="Topics"
              productSlug={productSlug}
              source="articleTopics"
              transferList={true}
            />
            {productSlug === POWER_MATERIAL_SLUG && (
              <FastField
                name="summary"
                component={TextAreaField}
                label="Summary"
                config={{ height: '500px' }}
              />
            )}
            <FastField
              name="text"
              component={RichTextAreaField}
              label="Text"
              config={{ height: '500px' }}
            />

            <FormRow>
              <FastField
                width={2}
                name="tableIcon"
                component={CheckboxField}
                label="Table Icon"
              />

              <FastField
                width={2}
                name="graphIcon"
                component={CheckboxField}
                label="Graph Icon"
              />

              <FastField
                width={2}
                name="imageIcon"
                component={CheckboxField}
                label="Image Icon"
              />
            </FormRow>

            <FormRow>
              <Field
                width={4}
                name="image"
                component={FileField}
                label="Image"
              />
            </FormRow>
            <FormRow
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(4, max-content)',
                gap: '10px'
              }}>
              <Tooltip
                on="hover"
                content="If this is checked, this RFP article will not be displayed in the website."
                trigger={
                  <FastField
                    name="notRfp"
                    component={CheckboxField}
                    label="Do not display RFP"
                  />
                }
              />
              <Tooltip
                on="hover"
                content="Display this free article in the featured section in the website."
                trigger={
                  <FastField
                    name="featured"
                    component={CheckboxField}
                    label="Featured"
                    checked={values.featured}
                    onChange={(_unused, { checked }) =>
                      this.handleFeaturedChange(checked, setFieldValue)
                    }
                  />
                }
              />
              <FastField
                name="free"
                component={CheckboxField}
                label="Free"
                checked={values.free}
                onChange={(_unused, { checked }) =>
                  this.handleFreeChange(checked, setFieldValue)
                }
              />

              <FastField
                name="embargoed"
                component={CheckboxField}
                label="Embargoed"
              />
            </FormRow>
            <FormRow>
              <UniSelect
                width={4}
                name="section"
                label="Publish in section"
                productSlug={productSlug}
                source="articleSections"
              />
              <UniSelect
                width={4}
                name="group"
                label="Publish in group"
                productSlug={productSlug}
                source="articleGroups"
              />
              <CompanyNewSelect
                fast={true}
                width={8}
                isMulti={true}
                isCreatable={true}
                name="relatedCompanies"
                label="Companies"
                defaultValue={defaultCompanySelectValue}
                productSlug={productSlug}
              />
            </FormRow>
            <RegionSelect width={8} name="regions" label="Regions" />
            <CountrySelect width={8} name="countries" label="Countries" />
            <UniSelect
              width={8}
              name="priceCategories"
              label="Prices"
              productSlug={productSlug}
              source="priceSerieCategories"
              transferList={true}
            />

            <UniSelect
              width={8}
              name="priceProducts"
              label="Products"
              productSlug={productSlug}
              source="priceSerieProducts"
              transferList={true}
            />

            <Header as="h3">Tags</Header>
            <FastField width={16} name="tags" component={TagSelect} />
            <NonFormValuesDisplay
              className={styles.nonFormValuesContainer}
              items={getNonFormItems(nonFormValues)}
            />
            <ErrorsAndButtonsInRow touched={touched} errors={errors}>
              <UpdateButtonInRow
                showWithExitBtn
                subject="Article"
                onClick={handleSubmit}
                disabled={isSubmitting || hasNoPermissionToUpdateRfpArticles}
                onUpdateAndExitClick={this.onUpdateAndExitClick}
              />
            </ErrorsAndButtonsInRow>
            <VisibleIf user={user} permissions={[PERMISSIONS.DELETE_ARTICLES]}>
              <Segment color="red">
                <Header as="h3">Dangerous Actions</Header>
                <WebArticleDeleteModal
                  successPage={WebArticleList}
                  params={{ productSlug }}
                  article={article}
                  articleDelete={articleDelete}
                  onOpen={() => this.setDeleteRequested(true)}
                  onClose={() => this.setDeleteRequested(false)}
                />
              </Segment>
            </VisibleIf>
          </Form>
        )}
      </Formik>
    );
  }
}

export default withRouter(WebArticleUpdateForm);
